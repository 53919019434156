import React, { useState, useEffect } from 'react';
import Header from './header';
import Body from './Body';
import PropertyDetails from './PropertyDetails';
import Spinner from './Spinner';
import { useParams } from 'react-router-dom';
import './App.css';

const MainPage = () => {
    const { firstVariable, secondVariable } = useParams();
    const [showContent, setShowContent] = useState(false); // State to manage content visibility

    const decodedFirstVariable = atob(firstVariable);
    const decodedSecondVariable = atob(secondVariable);

    useEffect(() => {
        // Set a timeout to change content visibility after 3 seconds
        const timer = setTimeout(() => {
            setShowContent(true);
        }, 3000);

        return () => clearTimeout(timer); // Cleanup the timer
    }, []);

    return (
        <div className="App">
             {!showContent && <Spinner />}
            <div style={{ visibility: showContent ? 'visible' : 'hidden' }}>
                {/* Pass decoded values to components only when not loading */}
                <Header userdetals={decodedFirstVariable} listare={decodedSecondVariable} />
                <Body userdetals={decodedFirstVariable} listare={decodedSecondVariable} />
                <PropertyDetails userdetals={decodedFirstVariable} listare={decodedSecondVariable} />
            </div>
        </div>
    );
};

export default MainPage;
