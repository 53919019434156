import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import Pasul1 from './MainPage';
import Pasul2 from './AcceptingTermsAndConditionsModelNou1';
import Signature from './Signature';
import Pasul3 from './ViewInvoice';
import Finish from './Finish';
import MobileOnlyMessage from './MobileOnlyMessage'; // Import the new component

const App = () => {
    return (
        <Router>
            <Routes>
                {isMobile ? (
                    <>
                        <Route path="/:firstVariable/:secondVariable" element={<Pasul1 />} />
                        <Route path="sign" element={<Signature />} />
                        <Route path="/:firstVariable/:secondVariable/:thirdVariable" element={<Pasul2 />} />
                        <Route path="/:firstVariable/:secondVariable/:thirdVariable/pay" element={<Pasul3 />} />
                        <Route path="/:firstVariable/:secondVariable/:thirdVariable/payment-confirmation-successfully" element={<Finish />} />
                    </>
                ) : (
                    <Route path="*" element={<MobileOnlyMessage />} />
                )}
            </Routes>
        </Router>
    );
};

export default App;
