import './App.css';
import Header from './header';
import { useParams, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import './AcceptingTermsAndConditions.css';
import useListingDetails from './api';
import InvisibleTable from './TableContract';
import BankDetailsTable from './TablePayment';
import ScrollButtons from './ScrollTopBottom';
const MainPage = () => {
    const navigate = useNavigate();
    const { firstVariable, secondVariable, thirdVariable } = useParams();
    const decodedFirstVariable = atob(firstVariable);
    const decodedSecondVariable = atob(secondVariable);
    const decodedthirdVariable = atob(thirdVariable);
    // const noBorderStyle = { border: 'none' };
    
    const listStyle = {
        listStyleType: 'disc', // Use 'disc' for standard bullet points
        paddingLeft: '20px', // Adjust padding to ensure alignment
        margin: 0, // Optional: Removes default margin for tighter control
    };

    const itemStyle = {
        marginBottom: '10px', // Adds space between items
        fontSize: '16px', // Set font size for readability
        color: '#333', // Dark grey color for text for better readability
    };
    // State to manage the single checkbox
    const [isChecked, setIsChecked] = useState({
        acceptConditions: false,
        agreePayment: false,
        makePaymentIn24h: false
    });

    // Handle change in checkbox state
    const handleChange = (event) => {
        setIsChecked({
            ...isChecked,
            [event.target.name]: event.target.checked
        });
    };

    // Check if all checkboxes are checked
    const allChecked = isChecked.acceptConditions && isChecked.agreePayment && isChecked.makePaymentIn24h && isChecked.makePaymentIn24h2;


    // Fetch user and listing details using the custom hook
    const [data] = useListingDetails(decodedFirstVariable, decodedSecondVariable, decodedthirdVariable);

    // Safely handle data and user
    const ListingDetails = data?.listing ?? null;
    if (!ListingDetails) return <div className="spinner"></div>;

    const UserDetails = data?.user ?? null;
    if (!UserDetails) return <div className="spinner"></div>;
    const BankDetails = data?.bank_account ?? null;
    if (!BankDetails) return <div className="spinner"></div>;
    const today = new Date().toLocaleDateString('de-DE', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });
    // Function to handle checkbox change

    let totalInhabitants = 1; // Start counting with the user

    // Parse the 'inhabitants' JSON string into an array
    const inhabitants = JSON.parse(UserDetails.inhabitants);
    // const totalCost = parseFloat(ListingDetails.warmCost) + parseFloat(ListingDetails.price);

    // Add the number of inhabitants
    totalInhabitants += inhabitants.length;
    const formatPrice = (price) => {
        const numericValue = parseFloat(String(price).replace(',', '.'));
        return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(numericValue);
    };

    // Handle button click to navigate to the new URL
    const handleReserveClick = () => {
        // eslint-disable-next-line
    //     const decodedFirstVariable = atob(firstVariable);
    // const decodedSecondVariable = atob(secondVariable);
    // const decodedthirdVariable = atob(thirdVariable);
        // Use the `encodedVariable` to navigate
        const newUrl = `/${firstVariable}/${secondVariable}/${thirdVariable}/pay`;
        navigate(newUrl);
    }
    return (
        <div className="App">
            <Header userdetals={decodedFirstVariable} listare={decodedSecondVariable} />
            <h2>Immoscout24 Mietvertrag über Wohnraum</h2>

            <div className="terms-container">
                {/* Terms and Conditions in German */}
                <div className='line-height30'>
                    <h3>zwischen</h3>
                    <p>{BankDetails.full_name}</p>
                    <p>wohnhaft in {ListingDetails.address}, {ListingDetails.zip} {ListingDetails.city} als Vermieter</p>
                </div>

                <div className='line-height30'>
                    <h3>und</h3>
                    <p>{UserDetails.firstName} {UserDetails.lastName}</p>
                    <p>wohnhaft in {UserDetails.street} {UserDetails.houseNumber}, {UserDetails.postcode} {UserDetails.city} als mieter</p>
                </div>
                <div className='line-height30'>
                    – folgend Mieter genannt –
                </div>
                <hr></hr>


                <div className='line-height30'>
                    <h3>§1 Mietsache</h3>
                    <ol>
                        <li style={{ marginLeft: '18px' }}>
                            Der Vermieter vermietet an den Mieter zu Wohnzwecken die in der {ListingDetails.address}, {ListingDetails.zip} {ListingDetails.city}, links gelegene Wohnung.
                        </li>

                    </ol><br></br>
                    <p>Die Mietsache besteht aus:</p>
                    <ul style={{ marginLeft: '20px' }} >
                        <li>{ListingDetails.rooms} zimmer</li>
                        <li>{ListingDetails.bedroom} Schlafzimmer</li>
                        <li>{ListingDetails.livingroom} Wohnzimmer</li>
                        <li>{ListingDetails.bathroom} Bäder</li>
                        {ListingDetails.balcon > 0 && (
                            <li>{ListingDetails.balcon} Balkon </li>
                        )}
                        {ListingDetails.kitchen > 0 && (
                            <li>{ListingDetails.kitchen} Küche</li>
                        )}

                    </ul><br></br>
                    <div>
                        2. Die Größe der Wohnung beträgt ca. {ListingDetails.sizeArea} m². Sollte ein Balkon / eine Terrasse / eine Loggia / ein Dachgarten mitvermietet sein, wird dessen Fläche zu 30 % angerechnet.
                        Diese Angabe dient wegen möglicher Messfehler nicht zur Festlegung des Mietgegenstandes. Der
                        räumliche Umfang der gemieteten Sache ergibt sich vielmehr aus der Angabe der vermieteten Räume.
                    </div><br></br>
                    <div>
                        3. Der Mieter wird die Wohnung ab dem {ListingDetails.vacantFrom} miete
                    </div>

                    <br></br>
                    <div>
                        4. Die Benutzung der Mietsache für gewerbliche oder berufliche Zwecke bedarf der Zustimmung des Vermieters. Behördliche Genehmigungen hat der Mieter auf seine Kosten einzuholen und dem Vermieter unaufgefordert nachzuweisen. Die Gesamtzahl der Personen, die die Wohnung bewohnen
                        werden, beträgt {totalInhabitants}. Sollte sich diese Gesamtzahl ändern, ist der Mieter verpflichtet, dies dem Vermieter unverzüglich mitzuteilen. Hierbei ist Name und Anschrift der ein- bzw. ausziehenden Person anzugeben. Sollte einer von mehreren Mietern ausziehen, führt diese Mitteilung oder der Auszug nicht zur Entlassung aus dem Mietvertrag und stellt keinen Widerruf der Bevollmächtigung nach § 14 Abs. 2 dieses Vertrags dar.
                    </div><br></br>
                    <div>
                        5. Dem Mieter ist bekannt, dass in der Umgebung des Mietobjektes in den nächsten Jahren Bauarbeiten stattfinden können. Diese Besonderheiten und etwaige Bauarbeiten in unmittelbarer Nähe entsprechen dem vertragsgemäßen Zustand.
                    </div>
                    <br></br>
                    <div>
                        6. Der Mieter wird ausdrücklich darauf hingewiesen, dass der Keller nicht zur Lagerung von Gegenständen geeignet ist, die eine trockene Lagerung erfordern.
                    </div>
                    <br></br>
                    <h3>§2 Mietzeit</h3>
                    <br></br>
                    <div>
                        1. Das Mietverhältnis beginnt am {ListingDetails.vacantFrom} und läuft auf unbestimmte Zeit. Die Vertragsparteien sind
                        sich darüber einig, dass das Mietverhältnis unter Einhaltung der ordentlichen Kündigungsfrist frühestens zum 01.01.2030, ordentlich kündbar ist.
                    </div>
                    <br></br>
                    <div>
                        2. Die Parteien schließen die Anwendung des § 545 BGB (stillschweigende Verlängerung des Mietverhältnisses) aus. Setzt der Mieter den Gebrauch der Mietsache nach Ablauf der Mietzeit fort, so gilt das Mietverhältnis nicht als verlängert.
                    </div><br></br>

                    <h3>§3 Miete</h3><br></br>
                    <div>1. Die Zusammensetzung der monatlichen Miete zum Zeitpunkt des Vertragsschlusses ergibt sich wie folgt:</div>
                    <InvisibleTable ListingDetails={ListingDetails} />
                    <div>  Gemäß § 556 Abs. 1 BGB sämtliche Betriebskosten im Sinne des § 2
                        Betriebskostenverordnung (BetrKV) in der jeweils geltenden Fassung
                        (insbesondere die sonstigen Betriebskosten) hierauf leistet er Vorauszahlungen auf warme Betriebskosten</div>
                    <br></br>
                    <div>
                        2. Der Mieter trägt die Betriebskosten nach §§ 1, 2 BetrKV. Zu den sonstigen Betriebskosten im Sinne der 2 Nr. 17 BetrKV, die von den in § 2 Nr. 1-16 BetrKV benannten Betriebskosten nicht erfasst sind, gehören
                    </div>
                    <div><br></br>

                        <ul style={listStyle}>
                            <li style={itemStyle}>Schnee- und Eisbeseitigung, insbesondere der Beseitigung von Schneebrettern und Eiszapfen vom Dach</li>
                            <li style={itemStyle}>Dachrinnenreinigung und -beheizung</li>
                            <li style={itemStyle}>Wartung von Blitzschutzanlagen/Steigeleitungen</li>
                            <li style={itemStyle}>Wartung der Be- und Entlüftungsanlagen</li>
                            <li style={itemStyle}>Inspektion Trockenleitungen</li>
                            <li style={itemStyle}>Wartung der Notstromaggregate</li>
                            <li style={itemStyle}>Reinigung von Sickerschächten, Leerung und Wartung der Sickergruben</li>
                            <li style={itemStyle}>Wartung der Kinderspielgeräte</li>
                            <li style={itemStyle}>Spielsandaustausch</li>
                            <li style={itemStyle}>Pflege und Überprüfung der Gründächer</li>
                            <li style={itemStyle}>Wartung der Entrauchungsanlagen / Rauchabzugsanlagen</li>
                            <li style={itemStyle}>Wartung der Feuerlöscher/-anlagen</li>
                            <li style={itemStyle}>Kosten der Brandmeldeanlage und der Rauchwarnmelder (z.B. Wartung, Stromkosten; soweit nicht vom Mieter gemäß § 9 selbst vorgenommen)</li>
                            <li style={itemStyle}>Kosten der Notrufanlage im Aufzug (z.B. Miete)</li>
                            <li style={itemStyle}>Wartung der Elektroinstallationen</li>
                            <li style={itemStyle}>Wach- und Gebäudeschutz</li>
                            <li style={itemStyle}>Concierge</li>
                            <li style={itemStyle}>Wartung der Gasherde / -thermen</li>
                            <li style={itemStyle}>Wartung der Springbrunnen</li>
                            <li style={itemStyle}>Reinigung der Feuerstätten</li>
                            <li style={itemStyle}>Wartung der Gasleitungen</li>
                            <li style={itemStyle}>Müllmanagement</li>
                            <li style={itemStyle}>Kosten der Trinkwasserverordnung (Jährliche Prüfung der Anlage)</li>
                            <li style={itemStyle}>Kosten von Schwimmbad und Sauna</li>
                            <li style={itemStyle}>Kosten der Prüfung elektrischer Anlagen (DIN VDE 0100-600 und DIN VDE 0105-100), sog. E-Check</li>
                        </ul>
                    </div>
                    <div>3. Die entstehenden Betriebskosten können auf den Mieter unabhängig von der Lage seiner Wohnung im Gebäude umgelegt werden. Der Vermieter ist berechtigt, eine Änderung des Abrechnungszeitraums vorzunehmen, sofern ein sachlicher Grund für die Änderung des Abrechnungszeitraums vorliegt. </div>
                    <br></br>
                    <div>4. Der Mieter trägt die Kosten der Erstellung der Verbrauchsanalyse im Sinne von § 7 Abs. 2 HeizkV; daraus folgt jedoch keine Verpflichtung des Vermieters, eine Verbrauchsanalyse im Sinne des § 7 Abs. 2 S. 2 HeizkV zu erstellen.</div>
                    <br></br>
                    <div>5. Die Parteien vereinbaren, dass sonstige durch gesetzliche Regelung zugelassene oder sonst zulässigerweise neu entstehenden Betriebskosten gemäß den gesetzlichen Bestimmungen umgelegt werden können.</div>
                    <br></br>
                    <div>6. Für den Fall, dass die Wohnung mit Öfen, Gasheizungen, Nachtspeicheröfen oder ähnlichem beheizt wird, ist der Mieter verpflichtet, in eigenem Namen einen Vertrag mit dem entsprechenden Versorger seiner Wahl abzuschließen beziehungsweise auf seine Kosten die entsprechenden Heizmittel zu beschaffen.</div>
                    <br></br>

                    <h3>§ 4 Zahlung der Miete, Aufrechnung</h3><br></br>
                    <div>1. Die Miete ist monatlich im Voraus, spätestens bis zum dritten Werktag eines Monats zu entrichten</div><br></br>
                    <div>2. Die Miete ist zum Fälligkeitszeitpunkt unter Angabe der Mieternummer kostenfrei in einem Betrag auf das folgende Konto zu überweisen:</div><br></br>

                    <BankDetailsTable Bankdetails={BankDetails} ListingDetails={ListingDetails} />
                    <br></br>
                    <div>
                        3. Im Falle des Zahlungsverzuges ist der Vermieter berechtigt, Verzugszinsen in Höhe von 5 Prozentpunkten über dem jeweils geltenden Basiszinssatz sowie den Ersatz etwaiger weiterer Schäden zu verlangen. Der Mieter hat für jede Mahnung wegen verspäteter Zahlung des Mietzinses pauschalierte Mahnkosten von jeweils 2,50 EURO zu zahlen. Dem Vermieter bleibt die Geltendmachung eines weitergehenden Schadens unbenommen. Dem Mieter bleibt der Nachweis vorbehalten, ein Schaden sei überhaupt nicht oder wesentlich niedriger als die Pauschale entstanden.Der Mieter kann nur mit Forderungen aus dem Mietverhältnis aufrechnen, wenn sie unbestritten, rechtskräftig festgestellt oder entscheidungsreif sind. Dies gilt nicht für Mietzinsminderungen, die wegen der Vorfälligkeit des Mietzinses im laufenden Monat entstanden sind. Diese Rückforderungsbeträge eines eventuell zuviel bezahlten Mietzinses für den laufenden Monat können vom Mieter in den Folgemonaten zur Aufrechnung gebracht werden.
                    </div> <br></br>
                    <h3>§5 Einzugsermächtigung ./. SEPA - Lastschrift</h3><br></br>
                    <div>
                        Für den Fall, dass der Mieter dem Vermieterin eine Einzugsermächtigung bzw. die Ermächtigung im SEPA-Lastschriftverfahren gemäß Anlage zu diesem Mietvertrag erteilt und Einzug beispielsweise mangels Deckung des Kontos fehlschlägt, kann der Vermieter vom Mieter Schadensersatz zumindest in Höhe der entstandenen Bankrücklastgebühren verlangen. Für den Fall, dass der Einzug der fälligen Forderungen mehr als einmal fehlschlägt, ist der Vermieter nicht verpflichtet, weitere Mietforderungen einzuziehen.
                    </div><br></br>
                    <h3>§6 Betriebskostenabrechnung</h3><br></br>
                    <div>1. Der Abrechnungszeitraum für die Betriebskosten ist das Kalenderjahr.</div>
                    <div>2. Der Vermieter legt mit der ersten Abrechnung den Umlageschlüssel für die Betriebskosten nach billigem Ermessen fest.</div><br></br>
                    <div>3. Guthaben- oder Nachzahlungsbeträge sind vom Vermieter/Mieter spätestens bis zum Ablauf des folgenden Kalendermonats nach Zugang der Abrechnung an den Vermieter/Mieter zu zahlen. Bei erteilter Einzugsermächtigung werden Guthaben- oder Nachzahlungsbeträge mit der Miete des auf den Zugang der Abrechnung folgenden zweiten Monats verrechnet bzw. eingezogen. Hiervon unberührt bleibt das Recht des Vermieters Guthabenbeträge und das Recht des Mieters Nachzahlungsbeträge mit den dem Vermieter/Mieter zustehenden Forderungen zu verrechnen.</div><br></br>
                    <div>4. Sofern der Mieter die Einsichtnahme in die Belege der Betriebskostenabrechnung wünscht, erfolgt diese am Ort der Verwaltung. Unterhält die Verwaltung eine Außenstelle am Ort des Mietobjekts erfolgt die Einsichtnahme dort. Ist wegen der Entfernung zwischen Mietobjekt und Ort der Verwaltung bzw. der Außenstelle oder aufgrund anderer Umstände die Einsichtnahme in die Belege am Ort der Verwaltung für den Mieter unzumutbar, so werden ihm die Kopien der Belege gegen angemessene Kostenerstattung zur Verfügung gestellt. Der Mieter hat dann keinen Anspruch auf Einsicht in die Originalbelege.</div><br></br>
                    <h3>§7 Mietsicherheit</h3><br></br>

                    <div>1. Der Mieter stellt dem Vermieter eine Mietsicherheit in Höhe von {formatPrice(ListingDetails.deposit * 2)} EURO.</div><br></br>
                    <div>2. Zu diesem Zweck kann der Mieter den Betrag der Kaution auf das Konto überweisen:</div><br></br>
                    <BankDetailsTable Bankdetails={BankDetails} ListingDetails={ListingDetails} />
                    <div>3. Die erste Zahlung, einschließlich Miete und Kaution, muss so schnell wie möglich erfolgen, und der Nachweis dieser Zahlung muss im letzten Schritt des Mietprozesses erbracht werden.</div><br></br>
                    <div>4. Die Parteien vereinbaren ausdrücklich, dass die Mietsicherheit alle Ansprüche des Vermieters aus diesem Mietverhältnis, insbesondere auch etwaige Erstattungsansprüche von Rechtsverfolgungskosten und Zinsen aus derartigen Ansprüchen sichern soll.</div><br></br>
                    <h3>§8 Schönheitsreparaturen</h3>
                    <div>1. Der Vermieter übernimmt keine Schönheitsreparaturen.</div><br></br>
                    <div>2. Die Schönheitsreparaturen werden vom Mieter auf seine Kosten getragen.</div><br></br>
                    <div>3. Der Mieter übernimmt die Wohnung in nicht renoviertem Zustand.</div><br></br>
                    <h3>§9 Instandhaltung und Pflege der Mieträume</h3><br></br>
                    <div>1. Zeigt sich im Laufe der Mietzeit ein Mangel der Mietsache oder wird eine Maßnahme zum Schutz der Mietsache gegen eine nicht vorhergesehene Gefahr erforderlich, so hat der Mieter dies dem Vermieter unverzüglich anzuzeigen.</div><br></br>
                    <div>2. Der Mieter hat die Mietsache und die darin befindlichen Anlagen und Einrichtungen pfleglich und sachgemäß zu behandeln, sie frostfrei, trocken und frei von Ungeziefer zu halten. Er hat insbesondere für ordnungsgemäße Reinigung, Lüftung und Beheizung zu sorgen. Insoweit wird auf das beigefügte Merkblatt zum Heizen und Lüften verwiesen. Für Beschädigungen der Mietsache und des Gebäudes sowie der dazugehörigen Anlagen ist der Mieter ersatzpflichtig, soweit sie von ihm, von Angehörigen seines Haushaltes, seinen Untermietern, Besuchern, Verwandten, Angestellten oder von ihm bestellten Handwerkern usw. verursacht werden. </div><br></br>

                    <div>3. Die Kosten für kleine Instandhaltungen innerhalb der Wohnung trägt der Mieter. Diese kleinen Instandhaltungen umfassen nur das Beheben kleiner Schäden an Teilen innerhalb der Wohnung, die beim Gebrauch dem häufigen Zugriff des Mieters ausgesetzt sind oder sein können, wie zum Beispiel Lichtschalter, Steckdosen, Fenster- und Türverschlüsse.</div><br></br>

                    <div>4. Die Verpflichtung zur Kostentragung des Mieters beschränkt sich auf Reparaturen mit einem Gesamtaufwand von max. € 100,00 einschließlich Umsatzsteuer je Reparatur. Der entstehende jährliche Aufwand ist auf 6 % der Jahresnettokaltmiete beschränkt.</div><br></br>

                    <div>5. Der Mieter ist verpflichtet die Fußbodenentwässerung auf seinem Balkon/Loggia und/oder auf der Terrasse immer freizuhalten und regelmäßig zu säubern, um das Abfließen des Oberflächenwassers zu gewährleisten.</div><br></br>

                    <h3>§10 Bauliche Veränderungen durch den Mieter</h3><br></br>
                    <div>
                        1. Bauliche Änderungen jeglicher Art, insbesondere Eingriffe in die bauseitige Ausstattung von Elektro, Heizung, Lüftung und Sanitär, die der Mieter durchzuführen beabsichtigt, bedürfen der vorherigen Zustimmung des Vermieters. Dies gilt insbesondere für die Aufstellung und Inbetriebnahme von Feuerungsanlagen. Notwendige Genehmigungen der zuständigen Stellen (Baubehörde, Schornsteinfeger usw.) hat der Mieter auf seine Kosten einzuholen und dem Vermieter unaufgefordert 14 Tage vor Baubeginn in Kopie einzureichen.
                    </div><br></br>

                    <div>
                        2. Über diese baulichen Änderungen wird eine Vereinbarung zwischen den Parteien geschlossen werden, in der insbesondere die Kostentragung und eine Rückbauverpflichtung geregelt werden.
                    </div><br></br>

                    <div>
                        3. Der Mieter haftet auch für die Schäden, die dem Vermieter im Zusammenhang mit derartigen Veränderungen entstehen oder die eine Folge derartiger Veränderungen sind, soweit der Vermieter diese nicht zu vertreten hat.
                    </div><br></br>

                    <div>
                        4. Bauliche Änderungen, die der Mieter ohne Zustimmung des Vermieters durchführt oder durchgeführt hat, sind nach Aufforderung durch den Vermieter zu entfernen und der ursprüngliche Zustand wiederherzustellen. Kommt der Mieter der Aufforderung zur Entfernung nicht nach, so hat der Vermieter das Recht, den ursprünglichen Zustand auf Kosten des Mieters wiederherzustellen.
                    </div><br></br>

                    <div>
                        5. Hat der Mieter die Mietsache mit einer Einrichtung versehen, so kann der Vermieter die Ausübung des Wegnahmerechts des Mieters gemäß § 539 Abs. 2 BGB durch Zahlung einer angemessenen Entschädigung abwenden, wenn nicht der Mieter ein berechtigtes Interesse an der Wegnahme hat. Sollte der Vermieter die Einrichtung nicht übernehmen wollen, hat der Mieter diese auf seine Kosten zu entfernen und den ursprünglichen Zustand der Mietsache wiederherzustellen.
                    </div><br></br>

                    <div>
                        6. Das Anbringen, Aufstellen und die Benutzung von Außenantennen und/oder Satellitenschüsseln bedarf der vorherigen Zustimmung des Vermieters. Diese Zustimmung kann von der Stellung einer Sicherheitsleistung für den Rückbau abhängig gemacht werden. Für den Fall, dass vom Vermieter eine Gemeinschaftsantenne errichtet oder ein Anschluss an das Kabelfernsehnetz vorgenommen wird, verpflichtet sich der Mieter, seine Außenantenne und/oder Satellitenschüssel auf seine Kosten zu entfernen und evtl. Schäden am Gebäude auf seine Kosten zu beseitigen
                    </div><br></br>

                    <div>
                        7. Das Anbringen von Funkantennen ist nicht gestattet.
                    </div><br></br>
                    <h3>§11 Bauliche Veränderungen und Ausbesserungen durch den Vermieter</h3><br></br>
                    <div>
                        1. Der Mieter hat Maßnahmen zu dulden, die zur Erhaltung der Mietsache erforderlich sind. Der Mieter hat die in Betracht kommenden Räume und Raumteile zugänglich zu halten und darf die Ausführung der Arbeiten nicht behindern, andernfalls hat er den dadurch entstehenden Schaden zu ersetzen.
                    </div><br></br>

                    <div>
                        2. Die Sammelheizung und Warmwasserversorgung kann der Vermieter auf andere Heizstoffe umstellen oder z. B. an die Fernheizung anschließen lassen. Öfen und Herde darf er gegen solche anderer Betriebsart auswechseln.
                    </div><br></br>

                    <h3>§12 Gewährleistung</h3><br></br>
                    <div>
                        1. Soweit Sachmängel der Mietsache bei Vertragsschluss vorhanden waren und vom Vermieter nicht arglistig verschwiegen wurden, ist die verschuldensunabhängige Haftung des Vermieters gemäß §536a BGB ausgeschlossen.
                    </div><br></br>

                    <div>
                        2. Schadens- und Aufwendungsersatzansprüche des Mieters gemäß § 536a BGB sind im Übrigen auch dann ausgeschlossen, soweit der Vermieter weder vorsätzlich noch grob fahrlässig handelte und er die Sachmängel nicht arglistig verschwiegen hat.
                    </div><br></br>

                    <div>
                        3. Für sonstige Schadensersatzansprüche, die nicht im Zusammenhang mit Sachmängeln der Mietsache stehen, haftet der Vermieter nur bei Vorsatz oder grober Fahrlässigkeit.
                    </div><br></br>

                    <div>
                        4. Der Haftungsausschluss nach Ziffern 2. und 3. gilt nur für solche Ansprüche, die nicht auf einer Verletzung des Lebens, des Körpers oder der Gesundheit oder auf einer Verletzung einer Kardinalpflicht beruhen. Kardinalpflichten sind diejenigen wesentlichen Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglichen und auf deren Einhaltung der Mieter deshalb vertraut und vertrauen darf.
                    </div><br></br>

                    <div>
                        5. Im Fall eines Vermieterwechsels haftet der alte Vermieter nicht für den von dem neuen Vermieter zu ersetzenden Schaden. Ein Anspruch des Mieters gemäß § 566 Absatz 2 BGB ist insoweit ausgeschlossen.
                    </div><br></br>
                    <h3>§13 Betreten der Mieträume</h3><br></br>
                    <div>
                        1. Bei Vorlage eines sachlichen Grundes hat der Mieter dem Vermieter und/oder seinem Beauftragten die Besichtigung zu der Mietsache zu ermöglichen. Unter Angabe des Grundes der Besichtigung ist der Termin zu vereinbaren oder mit einer Frist von mindestens einer Woche anzukündigen. Er wird werktäglich in der Zeit von 8:00 bis 19:00 Uhr stattfinden.
                    </div><br></br>
                    <div>
                        2. Im Fall der Kündigung des Mietverhältnisses hat der Mieter dafür zu sorgen, dass Mietinteressenten die Wohnung nach Terminvereinbarung werktags in der Zeit zwischen 8:00 und 20:00 Uhr und sonntags von 10:00 bis 12:00 Uhr besichtigen können.
                    </div><br></br>
                    <div>
                        3. Bei Gefahr oder im Schadensfall ist dem Vermieter und/oder seinem Beauftragten das Betreten der Mieträume zu jeder Zeit und ohne Ankündigung gestattet.
                    </div><br></br>

                    <h3>§14 Personenmehrheit</h3><br></br>
                    <div>
                        1. Mehrere Personen als Mieter haften für alle Verpflichtungen aus dem Mietvertrag als Gesamtschuldner. Dies gilt insbesondere für die Kosten der Rechtsverfolgung einschließlich der Zwangsvollstreckung.
                    </div><br></br>
                    <div>
                        2. Die Mieter bevollmächtigen sich hiermit gegenseitig zur Abgabe und Entgegennahme von Willenserklärungen und sonstigen Erklärungen, und zwar unter gegenseitiger Befreiung von den Beschränkungen des § 181 BGB. Dies gilt nicht für Kündigungen und Erhöhungen der Nettokaltmiete. Die gegenseitige Bevollmächtigung kann aus wichtigem Grund widerrufen werden. Ein Widerruf der Vollmacht wird erst für Erklärungen wirksam, die nach seinem Zugang abgegeben werden.
                    </div><br></br>
                    <div>
                        3. Jeder Mieter muss Tatsachen in der Person oder dem Verhalten eines die Mietsache mitbenutzenden Familienangehörigen oder eines anderen berechtigten Benutzers, die das Mietverhältnis berühren oder Schadensersatz begründen, für und gegen sich gelten lassen.
                    </div><br></br>

                    <h3>§15 Verkehrssicherungspflicht</h3><br></br>
                    <div>
                        1. Der Mieter verpflichtet sich, auf und vor dem Grundstück die Gehwege regelmäßig zu reinigen und von Schnee und Eis freizuhalten bzw. zu streuen. Der Mieter übernimmt insoweit hinsichtlich des Mietgegenstands die Verkehrssicherungspflicht.
                    </div><br></br>
                    <div>
                        2. Der Mieter stellt den Vermieter im Innenverhältnis von Ansprüchen Dritter aus Verletzung dieser Verkehrssicherungspflicht insoweit in Bezug auf das Mietobjekt frei.
                    </div><br></br>

                    <h3>§16 Untervermietung</h3><br></br>
                    <div>
                        1. Der Mieter ist ohne die Erlaubnis des Vermieters nicht berechtigt, den Gebrauch der Mietsache einem Dritten zu überlassen, insbesondere sie weiter zu vermieten. Zur Erlangung der Erlaubnis hat der Mieter neben dem Namen, der Anschrift und der Telefonnummer des Dritten auch das berechtigte Interesse an der Überlassung im Sinne des § 553 BGB mitzuteilen. Die Erlaubnis kann der Vermieter dann verweigern oder widerrufen, wenn ein berechtigtes Interesse nicht dargetan wurde, in der Person des Dritten ein wichtiger Grund vorliegt, der Wohnraum übermäßig belegt würde oder dem Vermieter die Überlassung aus sonstigen Gründen nicht zugemutet werden kann. Der Mieter hat dem Vermieter den jeweils aktuellen Untermietvertrag unaufgefordert zur Kenntnis zu geben.
                    </div><br></br>
                    <div>
                        2. Der Vermieter ist zur Erhebung eines angemessenen Untermietzuschlags berechtigt.
                    </div><br></br>
                    <div>
                        3. Der Mieter darf durch die Untervermietung keine Gewinne erzielen.
                    </div><br></br>
                    <div>
                        4. Der Mieter tritt dem Vermieter schon jetzt für den Fall der Untervermietung die ihm gegen den Untermieter zustehenden Forderungen nebst Pfandrecht in Höhe der Mietforderungen des Vermieters zur Sicherheit ab. Der Vermieter nimmt die Abtretung an.
                    </div><br></br>

                    <h3>§17 Ansprüche bei Ende des Mietverhältnisses</h3><br></br>
                    <div>
                        1. Das Zurücklassen von Gegenständen in der Mietsache nach Beendigung des Mietverhältnisses durch den Mieter gilt als Erklärung der Aufgabe des Eigentums und Besitzes an den Gegenständen, wenn der Mieter vom Vermieter zur Abgabe einer Eigentums- und Besitzaufgabeerklärung unter Fristsetzung von 2 Wochen und dem Hinweis auf die Rechtsfolge aufgefordert wurde und der Mieter der Eigentums- und Besitzaufgabe nicht widerspricht.
                    </div><br></br>
                    <div>
                        2. Im Falle der Geltendmachung des Vermieterpfandrechts ist der Mieter verpflichtet, an der Trennung verwertbarer Pfandsachen von wertlosem Müll mitzuwirken.
                    </div><br></br>

                    <h3>§18 Hausverwaltung, Vollmacht</h3><br></br>
                    <div>
                        Die Parteien sind sich darüber einig, dass die jeweils zuständige Hausverwaltung berechtigt ist, sämtliche Willenserklärungen für den Vermieter abzugeben und in Empfang zu nehmen. Dies bezieht sich insbesondere auf Mieterhöhungen, Kündigungen und Abmahnungen.
                    </div><br></br>
                    <h3>§19 Sonstige Vereinbarungen</h3><br></br>
                    <div>
                        1. Wird/Ist zwischen den Vertragsparteien ein Mietverhältnis über einen Kraftfahrzeugstellplatz abgeschlossen, entsteht hierdurch keine Kopplung zwischen dem Wohnungsmietvertrag und dem Stellplatzmietvertrag. Teilkündigungen sind zulässig.
                    </div><br></br>
                    <div>
                        2. Dem Mieter ist es nicht gestattet, auf Gemeinschaftsflächen im Haus – insbesondere im Treppenhaus und dem Hausflur – zu rauchen.
                    </div><br></br>
                    <div>
                        3. Dem Mieter ist untersagt, auf dem Grundstück des Wohnhauses und in dessen unmittelbarer Nähe Tauben zu füttern.
                    </div><br></br>
                    <div>
                        4. Die Parteien sind sich darüber einig, dass Gemeinschaftsflächen, deren Gestaltung und deren Nutzung jederzeit durch den Vermieter geändert werden kann.
                    </div><br></br>
                    <div>
                        5. Kleintiere, wie Vögel, Zierfische, Hamster, Schildkröten und vergleichbare Tiere, aber auch Katzen darf der Mieter ohne Einwilligung des Vermieters im haushaltsüblichen Umfang halten, soweit nach Anzahl, Art und Unterbringung der Tiere eine Belästigung von Hausbewohnern und Nachbarn sowie Beeinträchtigungen der Mietsache nicht zu erwarten sind. Jede andere Tierhaltung, insbesondere die Haltung von Hunden, bedarf der Genehmigung des Vermieters. Diese Genehmigung kann aus wichtigem Grund versagt bzw. widerrufen werden.
                    </div><br></br>
                    <div>
                        6. Der Mieter verpflichtet sich, sich unverzüglich bei einem Stromanbieter anzumelden. Der Mieter wird darauf hingewiesen, dass – sollte er sich nicht bei einem Stromanbieter anmelden – durch die Nutzung der Stromversorgung in der Wohnung ein Vertrag mit dem Grundversorger zu Stande kommt. Sollte der Mieter der Pflicht nach Satz 1 nicht oder fehlerhaft nachkommen, hat er dem Vermieter daraus entstehende Schäden zu ersetzen oder Auslagen zu erstatten.
                    </div><br></br>
                    <div>
                        7. Der Mieter wird darauf hingewiesen, dass beim Aufstellen von Möbeln an Außenwänden ein Abstand zur Luftzirkulation eingehalten werden sollte, um Feuchtigkeits- und Schimmelschäden zu vermeiden. Der Mieter verpflichtet sich, regelmäßig – Anfang eines jeden Kalenderjahres - zu überprüfen, ob die Luftzirkulation ausreichend ist.
                    </div><br></br>
                    <div>
                        8. Diesem Mietvertrag sind folgende Anlagen beigefügt, die Bestandteil des Vertrages sind:
                        <ul style={{ marginLeft: '18px' }}>
                            <li>Energieausweis</li>
                            <li>Auskunft nach § 556g Abs. 1a BGB</li>
                            <li>Merkblatt zum Heizen und Lüften</li>
                            <li>Hausordnung</li>
                            <li>Einzugsermächtigung / SEPA-Lastschrift</li>
                        </ul>
                    </div><br></br>
                    <div>
                        9. Weitere Nebenabreden sind nicht getroffen. Es gilt das Recht der Bundesrepublik Deutschland.
                    </div><br></br>
                    <div>
                        10. Sollte eine der in dieser Urkunde oder den Anlagen enthaltenen Vereinbarung aus irgendeinem Grund unwirksam oder undurchführbar sein oder werden, so soll hierdurch die Gültigkeit der übrigen Vereinbarungen, sofern sie wirtschaftlich sinnvoll bleiben, nicht berührt werden. Unwirksame oder undurchführbare Bestimmungen sind durch wirksame zu ersetzen, die dem erkennbaren oder mutmaßlichen Willen entsprechen und eine den Umständen nach angemessene Regelung darstellen. Etwaige Lücken der Vereinbarung in dieser Urkunde sind nach ihrer wirtschaftlichen Zweckbestimmung auszufüllen.
                    </div><br></br>
                    <div>
                        11. Sollte mit dieser Vereinbarung ein Verbrauchergeschäft vorliegen erklärt der Vermieter, dass er zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucher-schlichtungsstelle nicht verpflichtet ist und an einem solchen nicht teilnimmt.
                    </div><br></br>


                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                        // This sets the div to take the full height of the viewport
                    }}>
                        Unterschrift(en)
                    </div>
                    <hr></hr><br></br>

                    <div style={{
                        display: 'grid', gridTemplateColumns: 'auto auto', gap: '20px', alignItems: 'center',
                        justifyContent: 'center', columnGap: '78px'
                    }}>
                        <div style={{ fontWeight: 'bold' }}>Ort, Datum</div>
                        <div style={{ fontWeight: 'bold' }}>Mieter</div>
                        <div>{today}</div> {/* Placeholder for the actual date value */}
                        <div>{UserDetails.firstName} {UserDetails.lastName}</div>
                    </div>



                </div>

                <form>
                    <div>
                        <input
                            type="checkbox"
                            name="acceptConditions"
                            checked={isChecked.acceptConditions}
                            onChange={handleChange}
                        />
                        <label>Ich akzeptiere alle oben genannten Bedingungen.</label>
                    </div><br></br>
                    <div>
                        <input
                            type="checkbox"
                            name="agreePayment"
                            checked={isChecked.agreePayment}
                            onChange={handleChange}
                        />
                        <label>Ich bin mit der Zahlung einverstanden.</label>
                    </div><br></br>
                    <div>
                        <input
                            type="checkbox"
                            name="makePaymentIn24h"
                            checked={isChecked.makePaymentIn24h}
                            onChange={handleChange}
                        />
                        <label>Ich werde die Zahlung innerhalb von 24 Stunden vornehmen.</label>
                    </div>
                    <br></br>
                    <div>
                        <input
                            type="checkbox"
                            name="makePaymentIn24h2"
                            onChange={handleChange}
                        />
                        <label>Durch Drücken der Schaltfläche „Weiterfahren“ übernehme ich die Verantwortung, die Zahlung abzuschließen und den Zahlungsnachweis vorzulegen, wie im letzten Schritt dieses Mietvorgangs verlangt.</label>
                    </div>
                    <br></br>
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
  <button 
    style={{
      background: allChecked ? '#00FFD0' : 'white', // Use '#00FFD0' when allChecked is true, else use default background
      color: allChecked ? 'black' : 'black', // Use 'black' when allChecked is true, else use default color
      
      cursor: allChecked ? 'pointer' : 'not-allowed', // Change cursor based on allChecked state
      border:'1px solid grey',
      borderRadius:'5px'
    }}
    onClick={handleReserveClick} 
    disabled={!allChecked}
  >
    Fortfahren
  </button>
</div>

                </form>
                <br></br><br></br><br></br><br></br>
            </div>
            <ScrollButtons />
        </div>
    );
};

export default MainPage;
