import React from 'react';

const InvisibleTable = ({ ListingDetails }) => {
    // Define style objects
    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%', // Use full width to utilize available space
        maxWidth: '600px', // Max width for better readability on large screens
        margin: '20px auto', // Center the table and give some spacing from other elements
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)' // Subtle shadow for depth
    };

    const rowStyle = {
        borderBottom: '1px solid #ccc' // Subtle border for rows
    };

    const headerStyle = {
        background: '#f8f8f8', // Light grey background for header cells
        fontWeight: 'bold', // Make header text bold
        padding: '10px 20px', // Padding for better spacing within cells
        textAlign: 'left' // Align text to the left
    };

    const cellStyle = {
        padding: '10px 20px', // Consistent padding for all cells
        textAlign: 'right' // Right align for the numeric values
    };

    const totalCost = parseFloat(ListingDetails.warmCost) + parseFloat(ListingDetails.price);

    return (
        <table style={tableStyle}>
            <tbody>
                <tr style={headerStyle}>
                    <td style={cellStyle}>Nettokaltmiete</td>
                    <td style={cellStyle}>{ListingDetails.price} €</td>
                </tr>
                
                <tr style={rowStyle}>
                    <td style={cellStyle}>Betriebskosten (warm)</td>
                    <td style={cellStyle}>{ListingDetails.warmCost} €</td>
                </tr>
                <tr style={headerStyle}>
                    <td style={cellStyle}>Miete Gesamt</td>
                    <td style={cellStyle}>{totalCost.toFixed(2)} €</td>
                </tr>
            </tbody>
        </table>
    );
};

export default InvisibleTable;
