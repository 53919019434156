import React, { useState, useEffect } from 'react';

const ScrollButton = () => {
  const [atTop, setAtTop] = useState(true); // State to track if the user is at the top of the page

  // Check scroll position to toggle button
  useEffect(() => {
    const handleScroll = () => {
      setAtTop(window.pageYOffset < 50); // Considered at top if scrolled less than 50 pixels
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    return () => {
      // Cleanup listener on component unmount
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  // Scroll to the bottom of the page
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
    });
  };

  // Styles for the button
  const buttonStyle = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    background: '#00FFD0',
    color: '#000',
    border: 'none',
    borderRadius: '50%', // Circular button
    padding: '10px',
    cursor: 'pointer',
    fontSize: '24px', // Larger icons
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50px', // Fixed size for the circle
    height: '50px', // Fixed size for the circle
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease'
  };

  return (
    <button style={buttonStyle} onClick={atTop ? scrollToBottom : scrollToTop}>
      {atTop ? '⬇️' : '⬆️'} {/* Display down arrow when at top, up arrow when not */}
    </button>
  );
};

export default ScrollButton;
