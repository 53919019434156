import React, { useState, useEffect, useRef } from 'react';
import addressSVG from './address_map.svg'; // Import the SVG

const apikey = 'ordET-N3ovTelgSrgd7zwcKWLLJQS4foybzwB2DMxwI';

function GeocodeComponent({ address, city, zip }) {
    const [coordinates, setCoordinates] = useState(null);
    const [error, setError] = useState(null);
    const mapRef = useRef(null); // Reference to the map container
    const mapInstanceRef = useRef(null); // Reference to store the map instance

    useEffect(() => {
        // Function to perform the geocode lookup
        const handleGeocode = async () => {
            const url = `https://geocode.search.hereapi.com/v1/geocode?q=${encodeURIComponent(address)},${encodeURIComponent(city)},${encodeURIComponent(zip)}&apikey=${apikey}`;

            try {
                const response = await fetch(url);
                if (!response.ok) throw new Error('Failed to fetch geocode data');

                const data = await response.json();
                if (data.items && data.items.length > 0) {
                    const location = data.items[0].position;
                    setCoordinates(location);
                    setError(null); // Clear any previous error
                } else {
                    setError('No geocode results found.');
                    setCoordinates(null);
                }
            } catch (err) {
                setError('Error fetching geocode data.');
                console.error('Error:', err);
                setCoordinates(null);
            }
        };

        // Call the geocode function when component mounts or when props change
        handleGeocode();
    }, [address, city, zip]);

    useEffect(() => {
        if (coordinates && mapRef.current && !mapInstanceRef.current) {
            // Initialize the map only if it hasn't been initialized already and mapRef is defined
            const initializeMap = () => {
                const platform = new window.H.service.Platform({ apikey });
                const defaultLayers = platform.createDefaultLayers();

                const map = new window.H.Map(
                    mapRef.current,
                    defaultLayers.vector.normal.map,
                    {
                        center: { lat: coordinates.lat, lng: coordinates.lng },
                        zoom: 14,
                        pixelRatio: window.devicePixelRatio || 1
                    }
                );

                // Create a custom icon using the imported SVG
                const icon = new window.H.map.Icon(addressSVG, { size: { w: 32, h: 32 } });

                // Add a marker at the coordinates with the custom icon
                const marker = new window.H.map.Marker(
                    { lat: coordinates.lat, lng: coordinates.lng },
                    { icon: icon }
                );
                map.addObject(marker);

                // Save the map instance to prevent reinitialization
                mapInstanceRef.current = map;

                // No interaction: Do not add H.mapevents.Behavior to disable panning/zooming

                // Optional UI controls (e.g., zoom buttons)
                window.H.ui.UI.createDefault(map, defaultLayers);

                // Cleanup function
                return () => {
                    if (mapInstanceRef.current) {
                        mapInstanceRef.current.dispose();
                        mapInstanceRef.current = null;
                    }
                };
            };

            // Use requestAnimationFrame to wait for the map container to be ready
            requestAnimationFrame(initializeMap);
        }
    }, [coordinates]); // Re-run map setup if coordinates change and map is not initialized

    return (
        <div>
            {coordinates ? (
                <div>
                    <div ref={mapRef} style={{ width: '100%', height: '400px', marginTop: '10px' }} />
                </div>
            ) : error ? (
                <p style={{ color: 'red' }}>{error}</p>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
}

export default GeocodeComponent;
