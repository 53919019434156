import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ConfirmationPage = ({decodedFirstVariable, decodedThirdVariable, decodedSecondVariable}) => {
    const [paymentConfirmed, setPaymentConfirmed] = useState(false);
    const [proofUploaded, setProofUploaded] = useState(false);
    const navigate = useNavigate();
    
    const encodedFirstVariable = btoa(decodedFirstVariable);
    const encodedSecondVariable = btoa(decodedThirdVariable);
    const encodedThirdVariable = btoa(decodedSecondVariable);

    const handlePaymentConfirmationChange = () => {
        setPaymentConfirmed(!paymentConfirmed);
    };

    const handleProofUploadConfirmationChange = () => {
        setProofUploaded(!proofUploaded);
    };

    const handleSubmit = () => {
        if (paymentConfirmed && proofUploaded) {
            navigate(`/${encodedFirstVariable}/${encodedSecondVariable}/${encodedThirdVariable}/payment-confirmation-successfully`); // Corrected path
        } else {
            alert('Please confirm all the required fields.');
        }
    };

    return (
        <div style={{
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center', 
            minHeight: '', // Ensures the content vertically centers in full viewport height
            backgroundColor: '', // Light background color for the whole page
            padding: ''
        }}>
            <div style={{
                backgroundColor: '#fff', // White background for the form area
                boxShadow: '', // Subtle shadow to lift the element
                padding: '20px', 
                borderRadius: '8px', // Rounded corners
                width: '100%', 
                maxWidth: '454px', // Maximum width of the form container
                textAlign: 'left', // Aligns the text to the left
                marginBottom: '20px'
            }}>
                <div style={{ marginBottom: '20px' }}>
                    <input
                        type="checkbox"
                        id="paymentConfirmed"
                        checked={paymentConfirmed}
                        onChange={handlePaymentConfirmationChange}
                    />
                    <label htmlFor="paymentConfirmed" style={{ marginLeft: '8px' }}>Ich bestätige, dass die Zahlung erfolgreich durchgeführt wurde.</label>
                </div>
                <div style={{ marginBottom: '20px' }}>
                    <input
                        type="checkbox"
                        id="proofUploaded"
                        checked={proofUploaded}
                        onChange={handleProofUploadConfirmationChange}
                    />
                    <label htmlFor="proofUploaded" style={{ marginLeft: '8px' }}>Ich bestätige, dass ich den Zahlungsbeleg hochgeladen habe.</label>
                </div>

                
            </div>
            <button style={{
                padding: '10px 20px', 
                fontSize: '16px', 
                backgroundColor: '#4CAF50', 
                color: 'white', 
                border: 'none', 
                borderRadius: '5px', 
                cursor: 'pointer', 
                transition: 'background-color 0.3s' // Smooth transition for hover effect
            }} onClick={handleSubmit}>
                Beenden
            </button>

            <br></br><br></br>
        </div>
    );
};

export default ConfirmationPage;
