// src/Header.js
import React, { useState, useRef, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import useListingDetails from './api';
import Spinner from './Spinner';

const Header = ({ userdetals, listare }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const drawerRef = useRef(null);
  

    const userId = userdetals;
    const listingId = listare;

    // Fetch user and listing details using the custom hook
    const [data, loading, error] = useListingDetails(userId, listingId);
    
    // Safely handle data and user
    const user = data?.user ?? null;
    const inhabitants = user?.inhabitants ? JSON.parse(user.inhabitants) : [];

    const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (drawerRef.current && !drawerRef.current.contains(event.target)) {
                setIsDrawerOpen(false);
            }
        };

        if (isDrawerOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDrawerOpen]);

    return (
        <header className="mobile-view">
            <div className="header-container">
                <img
                    src={`${process.env.PUBLIC_URL}/immoscout24.svg`}
                    alt="ImmobileScout Logo"
                    className="logo"
                />
                <div className="profile-header" onClick={toggleDrawer}>
                    {user ? (
                        <>
                            <div className="user-info" style={{ marginTop: '15px' }}>
                                <p className="user-name">{`${user.firstName} ${user.lastName}`}</p>
                            </div>
                            {user.image && (
                                <img
                                    src={user.image}
                                    alt={`${user.firstName} ${user.lastName}`}
                                    style={{
                                        width: '50px',
                                        height: '50px',
                                        borderRadius: '50%',
                                        marginRight: '0px',
                                        marginTop: '15px'
                                    }}
                                />
                            )}
                        </>
                    ) : (
                        <p></p>
                    )}
                </div>
            </div>

            {isDrawerOpen && <div className="overlay" onClick={toggleDrawer} />}

            <div className={`user-drawer ${isDrawerOpen ? 'open' : 'close'}`} ref={drawerRef}>
                <AiOutlineClose className="close-icon" onClick={toggleDrawer} />

                <div className="drawer-content">
                    {loading ? (
                        <div> <Spinner /> <br></br> <p>Please wait, network speed is very low</p> </div>
                    ) : error ? (
                        <p style={{ color: 'red' }}>{error}</p>
                    ) : user ? (
                        <>
                            {/* User Details */}
                            <div className="profile-section">
                                <h3>Kontaktdaten</h3>
                            <p style={{marginBottom:'5px', marginTop:'10px'}}><strong>E-Mail:</strong> {user.email}</p>         
                            <p style={{marginBottom:'5px', marginTop:'10px'}}><strong>Telefon:</strong> {user.phoneNumber}</p>     
                                <p style={{marginBottom:'5px', marginTop:'10px'}}><strong>Vorname:</strong> {user.firstName}</p>
                                <p style={{marginBottom:'5px', marginTop:'10px'}}><strong>Nachname:</strong> {user.lastName}</p>
                                <p style={{marginBottom:'5px', marginTop:'10px'}}><strong>Geburtstag:</strong> {user.birthDay}-{user.birthMonth}-{user.birthYear}</p>
                                <p style={{marginBottom:'5px', marginTop:'10px'}}><strong>Adresse:</strong> {user.street} {user.houseNumber}, {user.postcode}, {user.city}</p>
                            </div>

                            {/* Inhabitants Section */}
                            {inhabitants.length > 0 && (
                                <div className="inhabitants-section">
                                    <h3>Bewohner</h3>
                                    {inhabitants.map((inhabitant, index) => (
                                        <div key={index} className="inhabitant-card">
                                            <p><strong>Vorname:</strong> {inhabitant.firstName}</p>
                                            <p><strong>Nachname:</strong> {inhabitant.lastName}</p>
                                            <p><strong>Geburtsjahr:</strong> {inhabitant.birthYear}</p>
                                            <p><strong>Typ:</strong> {inhabitant.inhabitantType === 'ADULT' ? 'Erwachsener' : 'Kind'}</p>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {/* Financial Information */}
                            <div className="financial-section">
                                <h3>Finanzinformationen</h3>
                                
                               
                                <p style={{marginBottom:'5px', marginTop:'10px'}}><strong>Profiltyp:</strong> {user.profileType}</p>
                                <p style={{marginBottom:'5px', marginTop:'10px'}}><strong>Premium-Typ:</strong> {user.premiumType}</p>
                                <p style={{marginBottom:'5px', marginTop:'10px'}}><strong>Beruf:</strong> {user.profession}</p>
                                <p style={{marginBottom:'5px', marginTop:'10px'}}><strong>Beschäftigungsart:</strong> {user.levelOfEmployment}</p>
                                <p style={{marginBottom:'5px', marginTop:'10px'}}><strong>Arbeitgeber:</strong> {user.employer}</p>
                                <p style={{marginBottom:'5px', marginTop:'10px'}}><strong>Monatliches Einkommen:</strong> {user.incomeAmount} €</p>
                                <p  style={{marginBottom:'5px', marginTop:'10px'}}>
                                    <strong>Letzte Kontoaktualisierung:</strong>{' '}
                                    {user.modificationDate &&
                                        new Date(parseInt(user.modificationDate)).toLocaleString('de-DE', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit'
                                        })}
                                </p>
                            </div>

                           
                        </>
                    ) : (
                        <p>Keine Daten verfügbar</p>
                    )}
                </div>
            </div>
        </header>
    );
};

export default Header;
