import React from 'react';

const MobileOnlyMessage = () => {
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh', // Takes full height of the viewport
            textAlign: 'center',
            padding: '20px'
        }}>
            <div>
            <img
                    src={`${process.env.PUBLIC_URL}/immoscout24.svg`}
                    alt="ImmobileScout Logo"
                    className="logo"
                    style={{width:'200px'}}
                /><br></br><br></br><br></br>
                <h1>Zugriff eingeschränkt</h1> <br></br>
<p>Aus Sicherheitsgründen werden alle Transaktionen über Mobilgeräte durchgeführt.</p> <br></br>
<p>Bitte greifen Sie von Ihrem Smartphone auf diese Anwendung zu.</p> <br></br>
            </div>
        </div>
    );
};

export default MobileOnlyMessage;
