// src/Body.js
import React, { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { useSwipeable } from 'react-swipeable';
import './Body.css';
import useListingDetails from './api';

const Body = ({ userdetals, listare }) => {
    // Fetch user and listing details using the custom hook
    const [data, loading, error] = useListingDetails(userdetals, listare);

    // Ensure data is available before accessing properties
    const listing = data?.listing ?? null;
    
    // Parse images from the API response
    const images = listing?.images ? JSON.parse(listing.images) : [];
    
    // State for managing modal visibility and current image index
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    // Open the modal and set the current image index
    const openModal = (index) => {
        setCurrentIndex(index);
        setIsModalOpen(true);
    };

    // Close the modal
    const closeModal = () => setIsModalOpen(false);

    // Navigate to the previous image
    const goToPrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    // Navigate to the next image
    const goToNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    // Swipeable handlers for detecting swipe gestures
    const handlers = useSwipeable({
        onSwipedLeft: goToNext,
        onSwipedRight: goToPrevious,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    // Render loading or error states
    if (loading) return <p>Loading...</p>;
    if (error) return <p style={{ color: 'red' }}>{error}</p>;

    return (
        <div className="body-container">
            {/* Check if images are available */}
            {images.length > 0 ? (
                <>
                    {/* Main Image Display */}
                    <div className="main-image-container" onClick={() => openModal(0)}>
                        <img src={images[0]} alt="Main View" className="main-image" />
                        <span className="image-counter">{images.length}</span>
                    </div>

                    {/* Modal for Fullscreen Image */}
                    {isModalOpen && (
                        <div className="image-modal" {...handlers}>
                            <AiOutlineClose className="close-icon" onClick={closeModal} />
                            <span className="modal-counter">
                                {currentIndex + 1}/{images.length}
                            </span>
                            <h3 className="modal-title">{listing.address}</h3>
                            <img
                                src={images[currentIndex]}
                                alt="Fullscreen View"
                                className="fullscreen-image"
                            />
                        </div>
                    )}
                </>
            ) : (
                <p>No images available</p>
            )}
        </div>
    );
};

export default Body;
