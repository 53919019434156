import React from 'react';
import './Spinner.css';

const Spinner = () => (
    <div className="spinner-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '15vh' }}>
        <div className="loader"></div>
    </div>
);

export default Spinner;
