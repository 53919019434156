import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './PropertyDetails.css';

// Corrected to destruct `bank_id` from props
const PropertyDetails = ({ bank_id }) => {
    const { firstVariable, secondVariable, thirdVariable } = useParams();
    const navigate = useNavigate();

    // Function to generate a new Base64-encoded variable
    const generateEncodedVariable = () => {
        // Combine all the variables into a single string before encoding
        const newVariable = `${firstVariable},${secondVariable},${thirdVariable}`;
        return btoa(newVariable);
    };
    const thirdVariableEncoded = btoa(bank_id);
    // Handle button click to navigate to the new URL
    const handleReserveClick = () => {
        // eslint-disable-next-line
        const encodedVariable = generateEncodedVariable();
        // Use the `encodedVariable` to navigate
        const newUrl = `/${firstVariable}/${secondVariable}/${thirdVariableEncoded}`;
        navigate(newUrl);
    };

    return (
        <button className="sticky-reserve-button" onClick={handleReserveClick}>
            Jetzt reservieren
        </button>
    );
};

export default PropertyDetails;
