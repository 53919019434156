import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import './Signature.css';

const SignaturePad = ({ onSave }) => {
    const sigCanvas = useRef(null);
    const [isSigned, setIsSigned] = useState(false);

    // Function to clear the signature pad
    const clearSignature = () => {
        sigCanvas.current.clear();
        setIsSigned(false);
    };

    // Function to handle saving the signature
    const saveSignature = () => {
        if (sigCanvas.current.isEmpty()) {
            alert("Bitte geben Sie Ihre Unterschrift ein.");
            return;
        }
        const signatureDataURL = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        onSave(signatureDataURL);
    };

    // Function to check if a signature is drawn
    const handleEnd = () => {
        setIsSigned(!sigCanvas.current.isEmpty());
    };

    return (
        <div className="signature-container">
            <h3>Bitte hier unterschreiben:</h3>
            <SignatureCanvas
                ref={sigCanvas}
                canvasProps={{ className: 'signature-canvas' }}
                onEnd={handleEnd}
            />
            <div className="button-container">
                <button onClick={clearSignature}>Löschen</button>
                <button onClick={saveSignature} disabled={!isSigned}>Unterschrift speichern</button>
            </div>
        </div>
    );
};

export default SignaturePad;
