import { useState, useEffect } from 'react';

function useListingDetails(userId, listingId, bankAccountId = null) {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            if (!userId || !listingId) return;

            setLoading(true);
            try {
                const queryParams = new URLSearchParams({ user_id: userId, listing_id: listingId });
                if (bankAccountId) queryParams.set('bank_account_id', bankAccountId);

                const response = await fetch(`https://clona.cyberslash.biz/backendapiclona/api.php?${queryParams.toString()}`);
                if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

                const result = await response.json();
                if (result.error) throw new Error(result.error);

                setData(result);
                setError(null);
            } catch (err) {
                setError(err.message);
                setData(null);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [userId, listingId, bankAccountId]);  // Add bankAccountId as a dependency

    return [data, loading, error];
}

export default useListingDetails;
